
.no-margin{margin: 0 !important;}
.no-margin-h{margin-left: 0 !important; margin-right: 0 !important;}
.no-margin-v{margin-top: 0 !important; margin-bottom: 0 !important;}
.no-margin-r{margin-right: 0 !important;}
.no-margin-l{margin-left: 0 !important;}
.m     {margin:       @m}
.m-xs  {margin:       @m-xs}
.m-sm  {margin:       @m-sm}
.m-md  {margin:       @m-md}
.m-lg  {margin:       @m-lg}

.m-v-xs{margin-top: @m-xs; margin-bottom: @m-xs;}
.m-v-sm{margin-top: @m-sm;margin-bottom: @m-sm;}
.m-v   {margin-top: @m;margin-bottom: @m;}
.m-v-md{margin-top: @m-md;margin-bottom: @m-md;}
.m-v-lg{margin-top: @m-lg;margin-bottom: @m-lg;}

.m-h-xs{margin-left:@m-xs; margin-right: @m-xs;}
.m-h-sm{margin-left:@m-sm;margin-right: @m-sm;}
.m-h   {margin-left:@m;margin-right: @m;}
.m-h-md{margin-left:@m-md;margin-right: @m-md;}
.m-h-lg{margin-left:@m-lg;margin-right: @m-lg;}

.m-t   {margin-top:   @m;}
.m-t-xs{margin-top:   @m-xs;}
.m-t-sm{margin-top:   @m-sm;}
.m-t-md{margin-top:   @m-md;}
.m-t-lg{margin-top:   @m-lg;}

.m-r   {margin-right: @m;}
.m-r-xs{margin-right: @m-xs;}
.m-r-sm{margin-right: @m-sm;}
.m-r-md{margin-right: @m-md;}
.m-r-lg{margin-right: @m-lg;}

.m-b   {margin-bottom:@m;}
.m-b-xs{margin-bottom:@m-xs;}
.m-b-sm{margin-bottom:@m-sm;}
.m-b-md{margin-bottom:@m-md;}
.m-b-lg{margin-bottom:@m-lg;}

.m-l   {margin-left:  @m;}
.m-l-xs{margin-left:  @m-xs;}
.m-l-sm{margin-left:  @m-sm;}
.m-l-md{margin-left:  @m-md;}
.m-l-lg{margin-left:  @m-lg;}

.m-n  {margin:       -@m}
.m-h-n{margin-left:  -@m;margin-right: -@m;}
.m-v-n{margin-top:   -@m;margin-bottom:-@m;}
.m-l-n{margin-left:  -@m;}
.m-r-n{margin-right: -@m;}
.m-t-n{margin-top:   -@m;}
.m-b-n{margin-bottom:-@m;}
