.border-wariant(@bg-color, @name){
  @color: mix(@bg-color, #fff, 10%);
  .b-@{name}{
    border-color: @bg-color;
  }
  .b-t-@{name}{
    border-top-color: @bg-color;
  }
  .b-r-@{name}{
    border-right-color: @bg-color;
  }
  .b-b-@{name}{
    border-bottom-color: @bg-color;
  }
  .b-l-@{name}{
    border-left-color: @bg-color;
  }
}

.no-border{
  border-color:
  transparent;
  border-width:0;
}
.no-borders{
  border-width:0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.b{
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.b-a{
  border: 1px solid @border-color;
}
.b-t{
  border-top: 1px solid @border-color;
}
.b-r{
  border-right: 1px solid @border-color;
}
.b-b{
  border-bottom: 1px solid @border-color;
}
.b-l{
  border-left: 1px solid @border-color;
}

.b-2x{
  border-width: 2px;
}
.b-3x{
  border-width: 3px;
}
.b-4x{
  border-width: 4px;
}
.b-5x{
  border-width: 5px;
}

.b-t-2x{
  border-top-width: 2px !important;
}
.b-t-3x{
  border-top-width: 3px !important;
}
.b-t-4x{
  border-top-width: 4px !important;
}
.b-t-5x{
  border-top-width: 5px !important;
}

.b-r-2x{
  border-right-width: 2px !important;
}
.b-r-3x{
  border-right-width: 3px !important;
}
.b-r-4x{
  border-right-width: 4px !important;
}
.b-r-5x{
  border-right-width: 5px !important;
}

.b-b-2x{
  border-bottom-width: 2px !important;
}
.b-b-3x{
  border-bottom-width: 3px !important;
}
.b-b-4x{
  border-bottom-width: 4px !important;
}
.b-b-5x{
  border-bottom-width: 5px !important;
}

.b-l-2x{
  border-left-width: 2px !important;
}
.b-l-3x{
  border-left-width: 3px !important;
}
.b-l-4x{
  border-left-width: 4px !important;
}
.b-l-5x{
  border-left-width: 5px !important;
}

.b-light{
  border-color: @brand-light;
}
.b-white{
  border-color: #fff;
}

.b-black{
  border-color: @brand-black;
}
.b-t-black{
  border-top-color: @brand-black;
}
.b-r-black{
  border-right-color: @brand-black;
}
.b-b-black{
  border-bottom-color: @brand-black;
}
.b-l-black{
  border-left-color: @brand-black;
}

.border-wariant(@brand-primary, primary);
.border-wariant(@brand-info, info);
.border-wariant(@brand-success, success);
.border-wariant(@brand-warning, warning);
.border-wariant(@brand-danger, danger);
.border-wariant(@brand-inverse, inverse);
.border-wariant(@brand-dark, dark);

.no-b-t{
  border-top-width: 0;
}
.no-b-r{
  border-right-width: 0;
}
.no-b-b{
  border-bottom-width: 0;
}
.no-b-l{
  border-left-width: 0;
}

.b-dashed{
  border-style: dashed !important;
}

@media (max-width: 991px) {
  .no-border-sm{border-width: 0;}
}

@media (max-width: 767px) {
  .no-border-xs{border-width: 0;}
}
