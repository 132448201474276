@import 'util.less';


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body, html {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

hr {
  border-width: 0;
  height: 1px;
  background-color: #ddd;
  margin: 15px 0;
}

md-content {
  color: #555;
}

.md-subheader h2 {
  font-size: 2em;
}

.inherit-line-height {
  line-height: inherit !important;
}

a, a:visited, a:hover {
  color: @brand-primary;
  text-decoration: none;
}

md-toolbar a, md-toolbar a:visited, md-toolbar a:hover {
  color: inherit;
}

md-toolbar.toolbar-sm {
  min-height: 0;
  height: auto;

  .md-toolbar-tools {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 0;
    height: auto;
  }
}

// a:hover {
//   text-decoration: underline;
// }

.middot {
  font-weight: bold;
  padding-left: @p-sm;
  padding-right: @p-sm;
}

#preloader {
  animation-duration: 1s;
  animation-timing-function: linear;
}

.spin-icon {
  animation: rotate 5s 2s linear infinite; 
}

#app-toolbar {
  z-index: 61; // above sidenav
}

.app-header-title {
  font-size: 150%;
  font-weight: lighter;
}

.app-header-title .icon {
  font-size: 150%;
}

.app-header-title .v75 {
  font-size: 120%;
}

.app-header-title .spacy {
  letter-spacing: 5px;
}

#logo-icon {
  animation-delay: 2s;
}

.no-margin {
  margin: 0 !important;
}

.issue-list-wrapper md-list {
  padding: 0;
}

.issue-list-wrapper a.md-button {
  margin: 0;
  line-height: 140%;
}


.filter-list md-select-value {
  min-width: 100px;
}
.filter-list md-input-container {
  padding-right: @p-sm;
}


md-chips.no-shadow md-chips-wrap {
  box-shadow: inherit;
}

md-card.card-lg, md-card.card-md, md-card.card-sm {
  
}

md-card.card-sm {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
}
md-card.card-md {
  max-width: 500px;
  min-width: 500px;
  width: 500px;
}
md-card.card-lg {
  max-width: 650px;
  min-width: 650px;
  width: 650px;
}

.auth-card-wrapper {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-card-wrapper-sm {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}


md-toolbar {
  animation-duration: 1s;
  animation-delay: 1s;
}

.animate-fade.ng-enter {
  position: relative;
  animation: fadeIn 1s;
}

// md-input-container textarea {
//   transition: height ease 0.3s;
// }

md-input-container.no-m {
  margin: 0;
  margin-top: @m-sm;
}

md-input-container.m-sm {
  margin: @m-sm 0;
  margin-top: @m-md;
}

.md-button.button-link {
  min-width: 0 !important;
  min-height: 30px !important;
  line-height: 30px !important;
}

.masonry-brick.project-card {
  width: 33.3333%;
}

.project-list {
  overflow: hidden;
}



/*----------
  Account
-----------*/
md-sidenav {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

// md-sidenav.proj-list {
//   overflow-y: auto;
// }

.md-sidenav-menu .menu-heading {
  color: @brand-primary;
}

.md-sidenav-menu .md-button.active, .md-sidenav-menu .md-button:hover, .md-sidenav-menu .md-button:focus {
  color: white;
  background: @brand-primary;
}



@media (min-width: 1920px) { // GT-LG
  .masonry-brick.project-card {
    width: 20%;
  }
} 

@media (max-width: 1919px) { // LG
  .masonry-brick.project-card {
    width: 25%;
  }
} 

@media (max-width: 1279px) { // MD
  .masonry-brick.project-card {
    width: 33.3333%;
  }
} 

@media only screen and (max-width: 959px) and (min-width: 0) and (orientation: portrait) { // MD - portrait

} 

@media only screen and (max-width: 959px) and (min-width: 0) and (orientation: landscape) { // MD - landscape

}

@media (max-width: 959px) { // SM
  md-card.card-lg, md-card.card-md, md-card.card-sm {
    max-width: auto;
    min-width: auto;
    width: auto;
    margin-left: 16px;
    margin-right: 16px;
  }

  .masonry-brick.project-card {
    width: 50%;
  }
} 

@media (max-width: 599px) { // XS  
  md-card.card-lg, md-card.card-md, md-card.card-sm {
    max-width: auto;
    min-width: auto;
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
  
  .filter-list md-select-value {
    min-width: 80px;
  }
  
  .md-subheader h2 {
    font-size: 1.5em;
  }
  
  .no-padding-xs {
    padding: 0 !important;
  }

  .masonry-brick.project-card {
    width: 100%;
  }
}



@keyframes rotate {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}